:root {
    --background: #1fbc6c;
    --background2: #02111B;
    --foreground: #042439;
}

.pdfViewerContainer {
    container-type: inline-size;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: clip;
}

.pdfViewerToolBar {
    width: 100%;
    min-height: 48px;
    height: max-content;
    background: var(--background2);
    padding-block: 8px;
    padding-inline: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
}

.toolBarButtonRow1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
}

.toolBarButtonRow2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    height: max-content;
}

.toolBarButton{
    display: none;
    padding-inline: 8px;
    padding-block: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    @container (min-width: 768px) {
        display: flex;
    }
    &:hover {
    background: var(--foreground);
}
}

.toolBarButton2 {
    display: flex;
    padding-inline: 8px;
    padding-block: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
        background: var(--foreground);
    }
}

.toolBarButton2Invisible {
    visibility: hidden;
    display: flex;
    padding-inline: 8px;
    padding-block: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
        background: var(--foreground);
    }
}

.divider {
    display: none;
    margin-inline: 8px;
    @container (min-width: 768px) {
        display: block;
    }
    width: 1px;
    height: 24px;
    background: white;
}

.divider2 {
    width: 2px;
    height: 24px;
    background: white;
}

.pdfViewerScaleSelector {
    display: none;
    @container (min-width: 640px) {
        display: block;
    }
    background: var(--background2);
    &:focus {
        outline: none;
    }
    height: 100%;
}

.invisibleDivider {
    display: none;
    @container (min-width: 768px) {
        display: block;
    }
    width: 4px;
    height: 24px;
}

.svgIcon {
    height: 24px;
    width: 24px;
}

.pdfPageNumInput {
    width: 48px;
    height: 100%;
    border-radius: 2px;
    &:focus {
        outline: none;
    }
    background: var(--foreground);
    text-align: center;
    padding-inline: 4px;
    border: 1px solid var(--background);
}

.pdfPageNumInputDisabled {
    width: 48px;
    height: 100%;
    border-radius: 2px;
    &:focus {
        outline: none;
    }
    &:disabled {
        cursor: not-allowed;
    }
    background: var(--foreground);
    text-align: center;
    padding-inline: 4px;
}

.pdfViewerScrollable {
    width: 100%;
    height: 425px;
    overflow: scroll;
    background: var(--background2);
    cursor: grab;
    &:active {
        cursor: grabbing;
    }
}

.pdfDocumentContainer {
    width: 100%;
    background: var(--background2);
    user-select: none;
}

.noDataContainer {
    display: flex;
    width: 100%;
    height: 489px;
    justify-content: center;
    align-items: center;
    background: var(--background2);
    color: white;
}